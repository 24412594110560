import React, { useEffect, useState } from "react";
import Main from "../components/layout/Main";
import { Table, Typography, Switch, message, Modal, Form, Input, InputNumber, Button, Select, Upload } from "antd";
import axios from "axios";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdDeleteForever } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { AiFillEdit } from "react-icons/ai";
import '../assets/styles/responsive.css';
import { FaDownload } from "react-icons/fa";
import { UploadOutlined } from '@ant-design/icons';

const { Option } = Select;

const { Paragraph } = Typography;

const Dashboard = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
const [categories, setCategories] = useState([]);

useEffect(() => {
  fetchDropdownData();
}, []);

const fetchDropdownData = async () => {
  const jwt = localStorage.getItem("jwt");

  try {
    const response = await axios.post(
      "https://admin.shopdesi.net.in/products/drop_down",
      { jwt }
    );
    const { Product_Category, Products_Tags } = response.data.Success;
    setCategories(Product_Category);
    setTags(Products_Tags);
  } catch (error) {
    console.error("Failed to fetch dropdown data:", error);
    message.error("Failed to fetch dropdown data.");
  }
};

  const ProductDetails = async (pageNo, pageSize) => {
    const jwt = localStorage.getItem("jwt");
    const postData = { 
      jwt: jwt,
      page_no: pageNo,
      page_size: pageSize,
    };

    setLoading(true);

    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "/products/view_all_products",
      data: postData,
    })
      .then(response => {
        setProducts(response?.data.Success);
        setTotal(response?.data.totalCount); // Adjust based on actual response structure
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    ProductDetails(page, pageSize);
  }, [page, pageSize]);

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleDelete = (productId) => {
    const jwt = localStorage.getItem("jwt");
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "https://admin.shopdesi.net.in/products/delete_product",
      data: { jwt, product_id: productId },
    })
      .then(response => {
        message.success("Product deleted successfully!");
        ProductDetails(page, pageSize); // Refresh the table
      })
      .catch(err => {
        console.error(err);
        message.error("Failed to delete product.");
      });
  };

   const handleSwitchChange = (checked, productId) => {
    const jwt = localStorage.getItem("jwt");
    const apiUrl = checked
      ? "https://admin.shopdesi.net.in/products/enable_product"
      : "https://admin.shopdesi.net.in/products/disable_product";
  
    axios({
      method: "POST",
      url: apiUrl,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        jwt: jwt,
        product_id: productId,
      },
    })
      .then(response => {
        // Update the status in the products state
        const updatedProducts = products.map(product =>
          product.ID === productId ? { ...product, Status: checked ? "enabled" : "disabled" } : product
        );
        setProducts(updatedProducts); // Update local state with the new status
        message.success(`Product ${checked ? "enabled" : "disabled"} successfully!`);
        
        // Optionally, re-fetch updated data if needed
        // ProductDetails(page, pageSize); // Assuming this function fetches the updated product list
      })
      .catch(error => {
        console.error(`Failed to ${checked ? "enable" : "disable"} product ${productId}:`, error);
        message.error(`Failed to ${checked ? "enable" : "disable"} product.`);
      });
  };
  
  
  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const [productImageURL, setProductImageURL] = useState(null);

  const handleFileChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }

    if (info.file.status === 'done') {
      const { thumbUrl } = info.file;
      setProductImageURL(thumbUrl);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
 

  const handleAddProduct = (values) => {
    console.log('Form Values:', values);

    const jwt = localStorage.getItem("jwt");

    if (!jwt) {
        message.error("JWT token not found. Please log in.");
        return;
    }

    let productTags = [];
    if (values.product_tags) {
        if (Array.isArray(values.product_tags)) {
            productTags = values.product_tags;
        } else if (typeof values.product_tags === 'string') {
            productTags = values.product_tags.split(',').map(tag => tag.trim());
        }
    }

    let productImage = '';
    if (values.product_image && values.product_image.fileList[0].thumbUrl) {
      productImage = values.product_image.fileList[0].thumbUrl;

      // const response = await fetch(productImage);
      // const blob = await response.blob();
      // productImage = URL.createObjectURL(blob);
  }

    const postData = {
        jwt,
        product_name: values.product_name,
        product_image: productImage,
        product_des: values.product_des,
        product_price: values.product_price,
        product_tags: productTags,
        product_category: values.product_category,
        product_discount: values.product_discount,
        product_qty: values.product_qty
    };

    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "https://admin.shopdesi.net.in/products/add_product",
      data: postData,
    })
      .then(response => {
        message.success("Product added successfully!");
        setIsAddModalVisible(false);
        form.resetFields();
        ProductDetails(page, pageSize); // Refresh the table
      })
      .catch(err => {
        console.error(err);
        message.error("Failed to add product.");
      });
  };

  const showEditModal = (product) => {
    setEditProductId(product.ID);
    form.setFieldsValue({
      product_name: product.Name,
      product_image: product.Img,
      product_des: product.Des,
      product_price: product.Price,
      product_tags: product.Tags.join(','), // Convert array to comma-separated string
      product_category: product.Category,
      product_discount: product.Discount,
      product_qty: product.Quantity
    });
    setIsEditModalVisible(true);
  };

  const handleUpdateProduct = (values) => {
    const jwt = localStorage.getItem("jwt");
    const postData = {
      jwt: jwt,
      product_name: values.product_name,
      product_image: values.product_image,
      product_des: values.product_des,
      product_price: values.product_price,
      product_tags: values.product_tags.split(','), // Convert comma-separated tags to array
      product_category: values.product_category,
      product_discount: values.product_discount,
      product_qty: values.product_qty,
      product_id: editProductId
    };

    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "https://admin.shopdesi.net.in/products/update_product",
      data: postData,
    })
      .then(response => {
        message.success("Product updated successfully!");
        setIsEditModalVisible(false);
        form.resetFields();
        ProductDetails(page, pageSize); // Refresh the table
      })
      .catch(err => {
        console.error(err);
        message.error("Failed to update product.");
      });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
    },
    {
      title: 'Image',
      dataIndex: 'Img',
      key: 'Img',
      render: (text) => <img src={text} alt={text} style={{ width: '50px' }} />,
    },
    {
      title: 'Description',
      dataIndex: 'Des',
      key: 'Des',
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      key: 'Category',
    },
    {
      title: 'Tags',
      dataIndex: 'Tags',
      key: 'Tags',
      render: (tags) => (
        <>
          {tags.map((tag) => (
            <span key={tag} style={{ marginRight: 6 }}>{tag}</span>
          ))}
        </>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'Price',
      key: 'Price',
      render: (price) => <span>$ {price}</span>,
    },
    {
      title: 'Discount',
      dataIndex: 'Discount',
      key: 'Discount',
    },
    {
      title: 'Quantity',
      dataIndex: 'Quantity',
      key: 'Quantity',
    },
//     {
//       title: 'Status',
//       dataIndex: 'Status',
//       key: 'Status',
//       render: (status, record) => (
//         <Switch
//   className={record.Status === "disabled" ? "disabled-switch" : "bg-black"}
//   checked={record.Status === "enabled"}
//   checkedChildren="Enable"
//   unCheckedChildren="Disable"
//   onChange={(checked) => handleSwitchChange(checked, record.ID)}
// />

//       ),
//     },
{
  title: 'Status',
  dataIndex: 'Status',
  key: 'Status',
  render: (status, record) => (
    <Switch
      className={status === "disabled" ? "disabled-switch" : "bg-black"}
      checked={status === "enabled"}
      checkedChildren="Enable"
      unCheckedChildren="Disable"
      onChange={(checked) => handleSwitchChange(checked, record.ID)}
    />
  ),
},

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className="flex justify-center items-center gap-3">
          <MdDeleteForever size={25}
            onClick={() => handleDelete(record.ID)}
            className="cursor-pointer text-[red]"
          />
          {/* <Switch
            className={record.Status === "disabled" ? "disabled-switch" : "bg-black"}
            checkedChildren="Enable"
            unCheckedChildren="Disable"
            defaultChecked={record.Status === "enabled"} // Adjust based on your actual data
            onChange={(checked) => handleSwitchChange(checked, record.ID)}
          /> */}
          <AiFillEdit size={25}
            onClick={() => showEditModal(record)}
            className="cursor-pointer text-primaryColor"
          />
        </div>
      ),
    },
  ];

  const handleDownloadCSV = () => {
    const jwt = localStorage.getItem("jwt");
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "https://admin.shopdesi.net.in/products/download_all_products_csv",
      data: { jwt },
      responseType: 'blob'
    })
      .then(response => {
        // Prepare CSV file
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'products.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        message.success("CSV downloaded successfully!");
      })
      .catch(err => {
        console.error(err);
        message.error("Failed to download CSV.");
      });
  };
  

  return (
    <>
      <Main>
        <div className="flex justify-between items-center">
        <Button
          type="primary"
          icon={<IoMdAdd />}
          onClick={showAddModal}
          style={{ marginBottom: 16 }}
        >
          Add Product
        </Button>
        <Button type="primary" onClick={handleDownloadCSV} icon={<FaDownload  />} style={{ marginRight: 16 }}>
            Download CSV
          </Button>
          </div>
        <Table
          columns={columns}
          dataSource={products}
          rowKey="ID"
          pagination={{
            current: page,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
          }}
          loading={loading}
          onChange={handleTableChange}
          />
          <Modal
            title="Add Product"
            visible={isAddModalVisible}
            onCancel={() => setIsAddModalVisible(false)}
            footer={null}
          >
            <Form form={form} onFinish={handleAddProduct} layout="vertical">
              <Form.Item name="product_name" label="Product Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
             
<Form.Item
            name="product_image"
            label="Product Image"
            valuePropName="file"
          >
            <Upload
              listType="picture"
              maxCount={1}
              onChange={handleFileChange}
              accept=".jpg,.jpeg,.png"
              beforeUpload={() => false} // Prevent upload directly
            >
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
              <Form.Item name="product_des" label="Product Description" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="product_price" label="Product Price" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
<Form.Item name="product_tags" label="Tags">
  <Select mode="tags" placeholder="Select tags">
    {tags.map(tag => (
      <Select.Option key={tag} value={tag}>{tag}</Select.Option>
    ))}
  </Select>
</Form.Item>

<Form.Item name="product_category" label="Category">
  <Select placeholder="Select category">
    {categories.map(category => (
      <Select.Option key={category} value={category}>{category}</Select.Option>
    ))}
  </Select>
</Form.Item>

              <Form.Item name="product_discount" label="Product Discount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="product_qty" label="Product Quantity" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">Add Product</Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Edit Product"
            visible={isEditModalVisible}
            onCancel={() => setIsEditModalVisible(false)}
            footer={null}
          >
            <Form form={form} onFinish={handleUpdateProduct} layout="vertical">
              <Form.Item name="product_name" label="Product Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="product_image" label="Product Image" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="product_des" label="Product Description" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="product_price" label="Product Price" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="product_tags" label="Product Tags" rules={[{ required: true }]}>
          <Select
            mode="tags"
            placeholder="Select tags"
          >
            {tags.map((tag) => (
              <Option key={tag} value={tag}>
                {tag}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="product_category" label="Product Category" rules={[{ required: true }]}>
          <Select
            placeholder="Select category"
          >
            {categories.map((category) => (
              <Option key={category} value={category}>
                {category}
              </Option>
            ))}
          </Select>
        </Form.Item>
              <Form.Item name="product_discount" label="Product Discount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="product_qty" label="Product Quantity" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">Update Product</Button>
              </Form.Item>
            </Form>
          </Modal>
        </Main>
      </>
    );
  };
  
  export default Dashboard;