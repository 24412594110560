import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Breadcrumb,
  Button,
} from "antd";

import { NavLink } from "react-router-dom";
import axios from "axios";
import jwtDecode from "jwt-decode";
import '../../assets/styles/main.css'
import AdminProfile from "../AdminProfile";


const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header() {

  const {profile} = AdminProfile()

  const clearLocalStorage = () => {
    window.localStorage.clear();
    window.location.href = '/';
  }

 

  // useEffect(() => window.scrollTo(0, 0));

  const btnStyle = {
    color: '#fff',
    fontSize: '16px',
    border: '1px dashed #fff',
    backgroundColor: '#df4646',
  }


  useEffect(() => {
    // profiles();
  }, [])

  // const refreshInterval = 3600000;

  // useEffect(() => {
  //   View_Profile();

  //   const intervalId = setInterval(() => {
  //     View_Profile();
  //   }, refreshInterval);

  //   return () => clearInterval(intervalId);
  // }, []);

  // const View_Profile = async () => {
  //   const jwt = localStorage.getItem("jwt");
  //   const postdata = {
  //     project: process.env.REACT_APP_PROJECT,
  //     jwt: jwt,
  //   };

  //   const response = await axios({
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     url: process.env.REACT_APP_API_PATH_USER_ODECENT + "view_profile",
  //     data: postdata,
  //   })
  //     .then(async (res) => {
  //       const response = await res;
  //       if (response) {
  //         setProfile(response?.data?.Success);
  //       } else {
  //         toast.error(response?.data?.Error);
  //       }
  //       if (response?.data?.Error === "Session Expired") {
  //         localStorage.clear();
  //         window.location.href = "/";
  //         Navigate("/");
  //       }
  //     })
  //     .catch((err) => {
  //       if (err?.response?.data?.Error === "Session Expired") {
  //         setTimeout(() => {
  //           localStorage.clear();
  //           window.location.href = "/";
  //           Navigate("/");
  //           message.error(err?.response?.data?.Error);
  //         }, 100);
  //       }
  //     });
  // };


  return (
    <>
      <div className=" flex justify-between py-4">
        <div >
          <div className=" text-2xl font-medium">
            <span
              className=" text-black "
            >
              Hello {profile?.Admin_Name}
            </span>
          </div>
        </div>
        <div className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            // onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Button
            className="logoutbtn"
            onClick={clearLocalStorage}
            style={btnStyle}
          >
            Logout
          </Button>
        </div>
      </div>
    </>
  );
}

export default Header;
