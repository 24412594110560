import React, { useState, useEffect } from 'react'
import img1 from '../assets/images/BOT350.jpg'
import { Layout, Menu, Button, Card, Form, Input, message, Select, Modal } from "antd";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/black.png'
import { toast } from 'react-toastify';

const Login = () => {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()

    const LoginFunc = () => {
        const postData = {
            admin_name: userName,
            admin_password: password,
        }

        const response = axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            url: process.env.REACT_APP_API_PATH + "/account/login",
            data: postData
        }).then(response => {
            message.success(response?.data?.Message)
            localStorage.setItem('jwt', response?.data?.Success)
            navigate('/product')
            
        })
            .catch(err => {
                console.log(err)
                toast.error(err.response.data.Error)
            })
    }

    return (
        <>
            <div className={` h-screen w-screen`} style={{ backgroundImage: `url(${img1})`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
                <div className="absolute inset-0 text-white flex items-center bg-blur backdrop-blur-sm bg-white/20 justify-center">
                    <div className=' flex flex-col justify-center items-center '>
                        <div className='relative'>
                            <div className='w-[500px] h-auto rounded-lg'>
                                <div className="absolute inset-0 bg-white  rounded-xl"></div>
                                <div className=' relative z-10'>
                                    <div className=' mx-16'>
                                        {/* <div className=" pt-4 pr-6 pb-6 text-center text-white font-medium text-3xl">Login</div> */}
                                        <div className=' flex flex-col w-full gap-10 pt-6'>
                                            {/* <Link to="/dashboardnew">
                                                <img src={logo} alt="" className=' w-64 mx-auto' />
                                            </Link> */}
                                            <div>
                                                <Form
                                                    name="basic"
                                                    initialValues={{ remember: true }}
                                                    // onFinish={onFinish}
                                                    className=" flex flex-col"
                                                >
                                                    <Form.Item
                                                        name="username"
                                                    // rules={[{ required: true, message: 'Please input your username!' }]}
                                                    >
                                                        <Input placeholder="Username" className=' py-1 pl-4 text-lg' value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="password"

                                                    // rules={[{ required: true, message: 'Please input your password!' }]}
                                                    >
                                                        <Input.Password placeholder="Password" className=' py-1 pl-4 text-lg' value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                                    </Form.Item>

                                                    <Form.Item>
                                                        <button
                                                            // style={{ width: "100%" }}
                                                            type="primary"
                                                            htmlType="submit"
                                                            // className="btn-theme"
                                                            onClick={LoginFunc}
                                                            className=' w-full bg-primaryColor hover:bg-black text-white font-semibold text-lg py-1 rounded-xl border-2 border-primaryColor'
                                                        >
                                                            SIGN IN
                                                        </button>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login
