import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SessionExp } from './SessionExp';

function AdminProfile() {
    const [profile, setProfile] = useState("");

    const View_Profile = async () => {
        const jwt = localStorage.getItem("jwt");
        const postdata = {
            project: process.env.REACT_APP_PROJECT,
            jwt: jwt,
        };

        try {
            const response = await axios({
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                url: process.env.REACT_APP_API_PATH + "/account/view_profile",
                data: postdata,
            });
            const responseData = await response.data;
            if (responseData?.Success) {
                setProfile(responseData?.Success);
            } else {
                toast.error(responseData.Error);
            }
        } catch (err) {
            toast.error(err?.response?.data?.Error);
            SessionExp(err)
        }
    };

    useEffect(() => {
        View_Profile();
    }, [SessionExp]);

    return {  profile,View_Profile };
}

export default AdminProfile;
