import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import SignUp from './pages/SignUp';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css'
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./pages/ProtectedRoutes";
import UserDetails from "./pages/UserDetails";
import OrderList from "./pages/OrderList";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route index element={<SignIn />} /> */}
          <Route index element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/product" element={<ProtectedRoute Component={Dashboard} />}/>
          <Route exact path="/userlist" element={<ProtectedRoute Component={UserDetails} />}/>
          <Route exact path="/orderlist" element={<ProtectedRoute Component={OrderList} />}/>
          {/* <Route exact path="/dashboard" element={<ProtectedRoute Component={Dashboard} />} /> */}
        

          {/* <Route exact path="/dashboard" element={<ProtectedRoute Component={Dashboard} />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
