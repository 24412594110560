import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import Main from '../components/layout/Main';

function OrderList() {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleViewTable = async () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { 
      jwt: jwt,
      page_no: "1",
      page_size: "20",
    };

    setLoading(true);

    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: process.env.REACT_APP_API_PATH + "/user/user_orders",
      data: postData,
    })
      .then(response => {
        setUserList(response?.data.Success);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    handleViewTable();
  }, []);

  const columns = [
    {
      title: 'User ID',
      dataIndex: 'UserID',
      key: 'userID',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'Phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'email',
    },
    {
      title: 'Phone Verified',
      dataIndex: 'Phone_Verified',
      key: 'phoneVerified',
      render: (text) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Email Verified',
      dataIndex: 'Email_Verfied',
      key: 'emailVerified',
      render: (text) => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'status',
      render: (text) => (text ? 'Active' : 'Inactive'),
    },
  ];

  return (
    <>
    <Main>
    <div>
      {loading ? (
        <Spin tip="Loading...">
          <Table columns={columns} dataSource={userList} rowKey="UserID" />
        </Spin>
      ) : (
        <Table columns={columns} dataSource={userList} rowKey="UserID" />
      )}
    </div>
    </Main>
    </>

  );
}

export default OrderList;
