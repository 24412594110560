import { Link, useLocation, useNavigate } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";
import { FaUserCog } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import logo from "../../assets/images/black.png";
import '../../assets/styles/main.css'
import '../../assets/styles/responsive.css'
import AdminProfile from "../AdminProfile";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const [selectedItem, setSelectedItem] = useState(1); 
  const page = pathname.replace("/", "");

  const navigate = useNavigate();
  const { profile } = AdminProfile();

  const btnStyle = {
    color: '#fff',
    fontSize: '16px',
    border: '1px dashed #fff',
    backgroundColor: '#df4646',
    display: "block",
    margin: "auto",
    marginBottom: '1rem'
  };

  const clearLocalStorage = () => {
    window.localStorage.clear();
    window.location.href = '/';
  };

  useEffect(() => {
    const activeItemIndex = menuItems.findIndex((item) => item.to === pathname);
    if (activeItemIndex !== -1 && menuItems[activeItemIndex].subItems) {
      setSelectedItem(activeItemIndex);
    } else if (activeItemIndex !== 1) {
      setSelectedItem(null);
    }
  }, [pathname]);


  const menuItems = [
    {
      to: '/product',
      label: 'Product',
      icon: <LuLayoutDashboard size={22} />,
    },
    {
      to: '/userlist',
      label: 'User List',
      icon: <LuLayoutDashboard size={22} />,
    },
    // {
    //   to: '/orderlist',
    //   label: 'Order List',
    //   icon: <LuLayoutDashboard size={22} />,
    // },
    // {
    //   label: 'User',
    //   icon: <LuLayoutDashboard size={22} />,
    //   subItems: [
    //     { to: '/manageUser', label: 'User List', icon: <FaUserCog size={22} /> },
    //     { to: '/UpdateUser', label: 'Update User', icon: <FaUserCog size={22} /> },
    //   ]
    // },
    
    // { to: '/setting', label: 'Setting', icon: <IoMdSettings size={22} /> },
  ];

  const handleItemClick = (to, index) => {
    setSelectedItem(index);
    navigate(to);
  };

  return (
    <>
      <div className="bg-primaryColor h-full pt-2 rounded-xl flex flex-col justify-between">
        <div>
          <div className="brand">
            <div className="pb-2 mb-2 flex justify-center gap-2 items-center border-b-2 border-white">
              {/* <img src="img.png" alt="" style={{ width: '50px' }} /> */}
              <span className="text-xl text-white">Company Logo</span>
            </div>
          </div>

          <ul className="your-menu-class">
            {menuItems?.map((item, index) => (
              <li key={index} style={{ width: 'auto' }}>
                <div onClick={() => handleItemClick(item.to || '#', index)}>
                  <div className={`flex gap-2 py-1.5 mb-[10px] mx-2 pl-2 rounded-xl ${pathname === item.to ? 'bg-white text-primaryColor' : 'text-white'}`}>
                    <span className="">{item.icon}</span>
                    <div className="flex justify-between items-center w-full">
                      <span className="fontmed cursor-pointer">{item.label}</span>
                      {item?.subItems && item?.subItems?.length > 0 && (
                        <span>{selectedItem === index ? '▲' : '▼'}</span>
                      )}
                    </div>
                  </div>
                </div>
                {(selectedItem === index || index === 1) && item.subItems && (
                  <ul>
                    {item.subItems.map((subItem, subIndex) => (
                      <li key={subIndex} style={{ width: 'auto' }}>
                        <Link to={subItem.to}>
                          <div className={`flex gap-2 py-1.5 mb-[10px] mx-2 pl-2 rounded-xl ${pathname === subItem.to ? 'bg-white text-primaryColor' : 'text-white'}`}>
                            {subItem.icon && <span>{subItem.icon}</span>}
                            <span className="fontmed">{subItem.label}</span>
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div className="bg-secondaryColor flex justify-around items-center text-white font-semibold text-lg rounded-b-xl py-2">
          <FaRegUserCircle size={22} className="text-white" />
          Hello {profile?.Admin_Name}
          <AiOutlineLogout size={22} className="text-red-500" onClick={() => {
            navigate('/');
            localStorage.removeItem('jwt');
          }} />
        </div>
      </div>
    </>
  );
}

export default Sidenav;
